<template>
  <section class="filters-container">
    <b-row>
      <b-col cols="5">
        <b-form-input
          id="query"
          v-model="filters.query"
          placeholder="Buscar por nome do lote ou código da guia"
          debounce="500"
        />
      </b-col>
      
      <b-col cols="3">
        <b-form-input
          id="batch_number"
          v-model="filters.batch_number"
          placeholder="Nº lote"
          debounce="500"
        />
      </b-col>

      <b-col cols="3">
        <Period id="period" @select="handlePeriod" />
      </b-col>

      <b-col cols="1">
        <b-button id="btn-apply-filter" variant="outline-primary">Aplicar</b-button>
      </b-col>      
      
    </b-row>
  </section>
</template>
<script>
export default {
  components: {
    Period: () => import('@/components/General/Periods'),
  },
  props: {
    statusOptions: {
      type: Array,
      default: () => []
    },
    showStatus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      filters: {
        query: null,
        batch_number: null,
        period: {
          start: null,
          end: null
        }
      }
    }
  },
  methods: {
    handlePeriod(period) {
      this.filters.period.start = period.start
      this.filters.period.end = period.end
    },
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('change', newValue)
      },
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
.filters-container {
  margin: 20px 0;

  label {
    font-weight: 700;
  }
}
.selection-join {
  padding: 0 16px;
}
#btn-apply-filter{
  background: var(--surface-tertiary, #E7ECFE);
  border-color: var(--surface-tertiary, #E7ECFE);
  width: 100%;
}
</style>
